import { cn } from "@commerce-frontend/ui/helpers/styles";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import type { HTMLAttributes } from "react";
import { forwardRef } from "react";

export type Props = VariantProps<typeof sectionVariants> & HTMLAttributes<HTMLDivElement>;

export const Section = forwardRef<HTMLDivElement, Props>(
	({ className, backgroundColor, spacing, children, ...props }, ref) => {
		return (
			<section
				className={cn(
					sectionVariants({
						backgroundColor,
						spacing,
					}),
					className,
				)}
				ref={ref}
				{...props}
			>
				{children}
			</section>
		);
	},
);

Section.displayName = "Section";

export const sectionVariants = cva("", {
	variants: {
		backgroundColor: {
			white: "bg-white",
			gray: "bg-gray-50",
		},
		spacing: {
			top_bottom: "py-6xl lg:py-8xl",
			top: "pt-6xl lg:pt-8xl",
			bottom: "pb-6xl lg:pb-8xl",
			none: "",
		},
	},
	defaultVariants: {
		backgroundColor: "white",
		spacing: "top_bottom",
	},
});
