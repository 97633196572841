import { cn } from "@commerce-frontend/ui/helpers/styles";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import type { HTMLAttributes } from "react";

export type Props = VariantProps<typeof containerVariants> &
	HTMLAttributes<HTMLDivElement> & {
		ref?: React.Ref<HTMLDivElement>;
	};

export function Container({ className, size, children, gap, ref, ...props }: Props) {
	return (
		<div
			className={cn(
				containerVariants({
					size,
					gap,
				}),
				className,
			)}
			ref={ref}
			{...props}
		>
			{children}
		</div>
	);
}

Container.displayName = "Container";

export const containerVariants = cva("flex flex-col", {
	variants: {
		size: {
			large: "mx-auto w-full max-w-container px-container-sm lg:px-container",
			medium: "mx-auto w-full max-w-4xl px-container-sm lg:px-container",
			small: "mx-auto w-full max-w-2xl px-container-sm lg:px-container",
			full: "mx-auto w-full px-container-sm lg:px-container",
			full_bleed: "mx-auto w-full",
		},
		gap: {
			none: "",
			large: "gap-6xl",
			default: "gap-2xl",
		},
	},
	defaultVariants: {
		size: "large",
		gap: "default",
	},
});
