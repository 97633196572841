"use client"; // Error components must be Client Components

import { Button } from "@commerce-frontend/ui/base/Button";
import { Container } from "@commerce-frontend/ui/base/Container/Container";
import { Section } from "@commerce-frontend/ui/base/Section/Section";
import { captureException } from "@sentry/nextjs";
import { useEffect } from "react";

export default function Error({
	error,
	reset,
}: {
	error: Error & { digest?: string };
	reset: () => void;
}) {
	useEffect(() => {
		// Log the error to an error reporting service
		captureException(error);
		console.error(error);
	}, [error]);

	return (
		<Section>
			<Container className="flex items-center">
				<h2>Something went wrong!</h2>
				<Button
					onClick={
						// Attempt to recover by trying to re-render the segment
						() => reset()
					}
				>
					Try again
				</Button>
			</Container>
		</Section>
	);
}
